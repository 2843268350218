/* eslint-disable */
import WebrtcSender from "../webrtc-sender"

export default class WebrtcSenderDatachannel extends WebrtcSender {
  peer = null

  getCallerName() {
    return "WebrtcSenderDatachannel"
  }

  // Responds to the "video-answer" message sent to the caller
  // once the callee has decided to accept our request to talk.
  handleVideoAnswerMsg(msg) {
    //this.peer.handleAnswer(msg.payload)
    this.peer.pc.setRemoteDescription(new RTCSessionDescription(msg.payload))
  }

  handleVideoOfferMsg(msg) {
    let self = this
    let callerId = msg.from

    // console.log("handleVideoOfferMsg--sender", msg)
    console.log("sender--setRemoteDescription", msg.payload, msg.payload instanceof RTCSessionDescription)
    self.peer
      .setRemoteDescription(new RTCSessionDescription(msg.payload))
      .then(() => {
        // you can call answer with contstraints
        self.peer.createAnswer().then(function (answer) {
          // console.log("handleVideoOfferMsg--answer", JSON.stringify(answer))

          console.log("gotDescription2--", JSON.stringify(answer))

          // self.myPeerConnection.pc.setLocalDescription(answer);
          // if (self.bandwidthKilobits) {
          //   // // @todo maybe later set bandwidth for audio
          //   // answer.sdp = Sdp.setMediaBitrate(Sdp.setMediaBitrate(answer.sdp, "video", self.maxBandwidthKilobits), "audio", 100);
          //   answer.sdp = Sdp.setMediaBitrate(answer.sdp, "video", self.bandwidthKilobits);
          // }
          console.log("sender--setLocalDescription", answer)
          self.peer.setLocalDescription(answer).then(() => {
            // if (self.type == WEBRTC_CONNECTION_TYPE_SENDER) {
            //   self.onBandwidthChange(self.props.bandwidth)
            // }
            // if (self.bandwidthKilobits) {
            //   // // @todo this is for refresh bitrate limit in answer
            //   // answer.sdp = Sdp.setMediaBitrate(Sdp.setMediaBitrate(answer.sdp, "video", self.maxBandwidthKilobits), "audio", 100);
            //   //answer.sdp = Sdp.setMediaBitrate(answer.sdp, "video", self.bandwidthKilobits);
            //   answer.sdp = Sdp.updateBandwidthRestriction(
            //     answer.sdp,
            //     self.bandwidthKilobits
            //   )
            // }
            // if (self.maxAverageBitrateBits) {
            //   answer.sdp = Sdp.addStereoMaxAverageBitrate(
            //     answer.sdp,
            //     self.maxAverageBitrateBits
            //   )
            // }
            //answer.sdp = Sdp.removeBandwidthRestriction(answer.sdp);
            // let nickName = self.getNickName()
            var msg_send = {
              audBitrate: "128K",
              audEncoder: "opus",
              vidBitrate: "5500000",
              vidEncoder: self.getVideoEncoder(),
              from: "", //self.connection.id
              payload: answer,
              to: callerId,
              type: "answer",
              token: self.tokenVal,
              loginHash:
                "54BC9A3B458A4B53F605FCD9F66A645E6A7570AC1B57969E40CE2ADA44B00DBE",
              prefix: "webkit",
              roomType: "Video",
              sid: "",
              multicastServer: self.props.multicastServer,
              multicastType: self.props.multicastType,

              // "PeerEx": nickName,
              // "peer": nickName,
            }
            // console.log(
            //   "+++Sender datachannel-sender" + self.connection.id + " send answer -----",
            //   msg_send
            // )
            console.log('22222')
            self.connection.emit("message", JSON.stringify(msg_send))
            //});
          }).catch((err) => {
            console.log("setLocalDescription error", err)
          })
        })
      }).catch((err) => {
        console.log("setRemoteDescription error", err)
      })
  }

  // A new ICE candidate has been received from the other peer. Call
  // RTCPeerConnection.addIceCandidate() to send it along to the
  // local ICE framework.
  handleNewICECandidateMsg(msg) {
    console.log("handleNewICECandidateMsg--sender", msg)
    this.peer
      .addIceCandidate(msg.payload)
      .then(
        this.onAddIceCandidateSuccess,
        this.onAddIceCandidateError
      );
    //this.peer.processIce(msg.payload)
  }

  onAddIceCandidateSuccess() {
    console.log('AddIceCandidate success.');
  }
  onAddIceCandidateError(error) {
    console.log(`Failed to add Ice Candidate: ${error.toString()}`);
  }
}
