/* eslint-disable */
import WebrtcReceiver from "../webrtc-receiver"
import { WEBRTC_CONNECTION_TYPE_SENDER } from "../constants";
import Sdp from "../sdp";

export default class WebrtcReceiverDatachannel extends WebrtcReceiver {
  peer = null

  getCallerName() {
    return "WebrtcReceiverDatachannel"
  }

  // Responds to the "video-answer" message sent to the caller
  // once the callee has decided to accept our request to talk.
  handleVideoAnswerMsg(msg) {
    //this.peer.handleAnswer(msg.payload)
    console.log('33333')
    console.log("receiver--setRemoteDescription", msg.payload)
    this.peer.setRemoteDescription(new RTCSessionDescription(msg.payload)).then(() => {
      // console.log("setRemoteDescription ok")
    }).catch((err) => {
      console.log("setRemoteDescription error", err)
    })
  }

  handleVideoOfferMsg(msg) {
    let self = this
    let callerId = msg.from

    self.peer
      .setRemoteDescription(new RTCSessionDescription(msg.payload))
      .then(() => {
        // you can call answer with contstraints
        self.peer.createAnswer().then(function (answer) {
          console.log("handleVideoOfferMsg--answer", JSON.stringify(answer))

          // self.myPeerConnection.pc.setLocalDescription(answer);
          // if (self.bandwidthKilobits) {
          //   // // @todo maybe later set bandwidth for audio
          //   // answer.sdp = Sdp.setMediaBitrate(Sdp.setMediaBitrate(answer.sdp, "video", self.maxBandwidthKilobits), "audio", 100);
          //   answer.sdp = Sdp.setMediaBitrate(answer.sdp, "video", self.bandwidthKilobits);
          // }

          self.peer.setLocalDescription(answer).then(() => {
            // if (self.type == WEBRTC_CONNECTION_TYPE_SENDER) {
            //   self.onBandwidthChange(self.props.bandwidth)
            // }
            // if (self.bandwidthKilobits) {
            //   // // @todo this is for refresh bitrate limit in answer
            //   // answer.sdp = Sdp.setMediaBitrate(Sdp.setMediaBitrate(answer.sdp, "video", self.maxBandwidthKilobits), "audio", 100);
            //   //answer.sdp = Sdp.setMediaBitrate(answer.sdp, "video", self.bandwidthKilobits);
            //   answer.sdp = Sdp.updateBandwidthRestriction(
            //     answer.sdp,
            //     self.bandwidthKilobits
            //   )
            // }
            // if (self.maxAverageBitrateBits) {
            //   answer.sdp = Sdp.addStereoMaxAverageBitrate(
            //     answer.sdp,
            //     self.maxAverageBitrateBits
            //   )
            // }
            //answer.sdp = Sdp.removeBandwidthRestriction(answer.sdp);
            // let nickName = self.getNickName()
            var msg_send = {
              audBitrate: "128K",
              audEncoder: "opus",
              vidBitrate: "5500000",
              vidEncoder: self.getVideoEncoder(),
              from: "", //self.connection.id
              payload: answer,
              to: callerId,
              type: "answer",
              token: self.tokenVal,
              loginHash:
                "54BC9A3B458A4B53F605FCD9F66A645E6A7570AC1B57969E40CE2ADA44B00DBE",
              prefix: "webkit",
              roomType: "Video",
              sid: "",
              multicastServer: self.props.multicastServer,
              multicastType: self.props.multicastType,

              // "PeerEx": nickName,
              // "peer": nickName,
            }
            console.log(
              "+++Sender datachannel-receiver " + self.connection.id + " send answer -----",
              msg_send
            )
            self.connection.emit("message", JSON.stringify(msg_send))
            //});
          })
        })
      })
  }

  // A new ICE candidate has been received from the other peer. Call
  // RTCPeerConnection.addIceCandidate() to send it along to the
  // local ICE framework.
  handleNewICECandidateMsg(msg) {
    console.log("handleNewICECandidateMsg-receiver", msg.payload)
    this.peer
      .addIceCandidate(msg.payload)
      .then(
        this.onAddIceCandidateSuccess,
        this.onAddIceCandidateError
      );
    //this.peer.processIce(msg.payload)
  }

  onAddIceCandidateSuccess() {
    console.log('AddIceCandidate success.');
  }

  onAddIceCandidateError(error) {
    console.log(`Failed to add Ice Candidate: ${error.toString()}`);
  }

  makeOfferPreview(connTo, conn){
    let self = this
    console.log("makeOfferPreview--datachannel", self.peer)
    self.peer.createOffer().then(
      function(desc) {
        // console.log("gotDescription1--", JSON.stringify(desc))
        console.log("receiver--setLocalDescription", desc)
        self.peer.setLocalDescription(desc).then(() => {
          console.log("setLocalDescription ok")
          //  self.getOrCreateConnection().then((conn) => {
          var msg_send = {
            audBitrate: "128000",
            audEncoder: "opus",
            from: "", // conn.id,
            payload: desc,
            prefix: "webkit",
            roomType: "Video",
            sid: "",
            to: connTo.id,
            type: "offer",
            vidBitrate: "5500000",
            vidEncoder: self.getVideoEncoder(),
            token: self.tokenVal,
            loginHash:
              "54BC9A3B458A4B53F605FCD9F66A645E6A7570AC1B57969E40CE2ADA44B00DBE",
          }
          // console.log("makeOfferPreview--datachalle", msg_send, conn.id)
          console.log('1111', msg_send)
          conn.emit("message", JSON.stringify(msg_send))
          //})
        }).catch((err) => {
          console.log("setLocalDescription error", err)
        })
      },
      this.onCreateSessionDescriptionError
    )
  }

  onCreateSessionDescriptionError(error) {
    console.log('Failed to create session description: ' + error.toString())
  }
}

