<template>
  <!-- eslint-disable -->
  <div>
    <v-row class="px-3 mt-2">
      <v-col cols="6" md="3" v-if="$can('read', 'Superadmin')">
        <SelectSignalsAsync
          v-model="currentSignalKey"
          outlined
          label="Signal server"
          :multiple="false"
          item-text="data.name"
          item-value="_key"
          :menu-props="{ maxHeight: 304 }"
          :hint="signalUrlHint"
          persistent-hint
        />
      </v-col>
      <v-col cols="6" md="3">
        <SelectCompaniesAsync
          v-model="currentStreamsCompany"
          outlined
          label="Company"
          :multiple="false"
          item-value="_key"
          item-text="name"
          v-validate="'required'"
          :error-messages="
                    errors.collect(`form-bundle-event-settings.group_key`)
                  "
          data-vv-name="group_key"
          data-vv-validate-on="change"
          data-vv-as="company"
          field="group_key"
          :menu-props="{ maxHeight: 304 }"
          return-object
          :hint="signalRoomHint"
          persistent-hint
          :disabled="currentCommonRoom"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-checkbox
          v-model="currentCommonRoom"
          label="Use common room"
          class="mx-2 mt-3"
          dense
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row class="pa-3">
      <v-col cols="6" md="6">
        connSenderName: {{ connSenderName }} <br><br>
        connReceiverName: {{ connReceiverName }}
      </v-col>
    </v-row>

    <v-row class="pa-3">
      <v-col cols="6" md="6">
        <h2>Sender</h2>
        <v-btn color="indigo" @click="startPeerSender">
          Start peer sender
        </v-btn>

        <!--        <v-text-field-->
        <!--          v-model="senderPeerName"-->
        <!--          color="purple darken-2"-->
        <!--          label="Peer name"-->
        <!--          required-->
        <!--        />-->

        <!--        <v-btn dark color="green" class="ml-1" @click="startSenderPeer">-->
        <!--          Start sender peer-->
        <!--        </v-btn>-->

        <br><br>

        <v-btn color="indigo" @click="sendFiles">
          Send files
        </v-btn>

        <v-row class="pt-3">
          <v-col cols="12" md="12">
            <!--            <v-text-field-->
            <!--              v-model="intervalSendFiles"-->
            <!--              color="purple darken-2"-->
            <!--              label="Interval of send files (sec)"-->
            <!--              outlined-->
            <!--            ></v-text-field>-->

            <v-file-input
              v-model="files"
              color="deep-purple accent-4"
              counter
              label="File input"
              :multiple="false"
              placeholder="Select your files"
              prepend-icon="mdi-paperclip"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index < 2"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-3 mx-2"
                >
              +{{ files.length - 2 }} File(s)
            </span>
              </template>
            </v-file-input>
          </v-col>

          <v-col cols="12" md="12">
            <v-progress-linear
              v-model="sendProgressVal"
              color="amber"
              height="25"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </v-col>
        </v-row>

        <v-btn color="indigo" @click="sendTextMessage">
          Send text
        </v-btn>

        <v-text-field
          v-model="messageSendText"
          color="purple darken-2"
          label="Message send"
          required
          outlined
        ></v-text-field>


        <div>
          here2
          <div v-for="msg in messageReceived2">
            {{ msg }}
          </div>
        </div>
      </v-col>

      <v-col cols="6" md="6">
        <h2>Received</h2>

        <br><br>

        <v-row>
          <v-col cols="12" md="12">
            <v-select
              outlined
              v-model="remotePeerStream"
              :items="roomMembers"
              item-text="nickName"
              return-object
              :menu-props="{ maxHeight: '132' }"
              label="Available aspx stream"
              :hint="'Available peers'"
              persistent-hint
              dense
              @change="startReceivingSenderStream()"
            />
          </v-col>

          <v-col cols="12" md="12">
            <div>
              here
              <div v-for="msg in messageReceived">
                {{ msg }}
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="12">
            <v-btn color="indigo" @click="sendTextMessage2">
              Send text 2
            </v-btn>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field
              v-model="messageSendText2"
              color="purple darken-2"
              label="Message send"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-progress-linear
              v-model="receiveProgressVal"
              color="blue-grey"
              height="25"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </v-col>
          <v-col cols="12" md="12">
            <strong>Average Bitrate:</strong> {{ bitrate }} kbits/sec
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
/**
 * Send/receive stream preview images through WebRTC Datachannels
 */
import { mapGetters } from "vuex"
import WebrtcSenderDatachannel from "@/common/webrtc/data-channel/webrtc-sender-datachannel"
import WebrtcReceiverDatachannel from "@/common/webrtc/data-channel/webrtc-receiver-datachannel"
import DatachannelSender from "@/common/webrtc/data-channel/datachannel-sender"
import DatachannelReceiver from "@/common/webrtc/data-channel/datachannel-receiver"
import SelectSignalsAsync from "@/components/selects/SelectSignalsAsync"
import SelectCompaniesAsync from "@/components/selects/SelectCompaniesAsync"

export default {
  components: {
    SelectSignalsAsync,
    SelectCompaniesAsync,
  },

  data: () => ({
    remote: null,
    local: null,
    peerRemote: null,
    peerLocal: null,
    chRemote: null,
    chLocal: null,
    chRemote2: null,
    chLocal2: null,
    chRemoteFile: null,
    chLocalFile: null,
    messageSendText: "",
    messageSendText2: "",
    messageReceived: [],
    messageReceived2: [],
    // files: [],
    files: null,
    intervalSendFiles: 1,
    connSenderName: "DataChannelSender" + Math.floor((Math.random() * 10000) + 1),
    connReceiverName: "DataChannelReceiver" + Math.floor((Math.random() * 10000) + 1),
    sendProgress: {
      max: 0,
      value: 0
    },
    receiveProgress: {
      max: 0,
      value: 0
    },
    receiveBuffer: [],
    receivedSize: 0,
    timestampStart: null,
    bitrate: 0,
    signalServer: null,
    remotePeerStream: null,
    roomMembers: [],
  }),

  mounted() {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.$store.dispatch("getUserGroups", { params: { p: 1, pp: 500 } }).then(() => {
      self.$store.dispatch("streamsInitActiveCompany").then(() => {
        self.$store
          .dispatch("signalServersList", { params: { p: 1, pp: 500 } })
          .then(() => {
            self.$store.dispatch("streamsInitActiveSignal").then(() => {
              self.$store.dispatch("saveLoaderAction", false)
              console.log("self.streamsActiveSignalServerUrl", self.streamsActiveSignalServerUrl)
              self.createSignalConnections()
            })
          })
      })
    })
  },

  methods: {
    createSignalConnections() {
      let self = this
      let webrtcCommonProps = {
        signalUrl: self.streamsActiveSignalServerUrl,
        room: "data-channel-room-1",
      }
      // initiate WebRTC sender
      let senderProps = {
        ...webrtcCommonProps,
        ...{
          name: self.connSenderName,
        },
      }
      self.remote = new WebrtcSenderDatachannel(senderProps)

      // initiate WebRTC receiver
      let receiverProps = {
        ...webrtcCommonProps,
        ...{
          name: self.connReceiverName,
        },
      }
      self.local = new WebrtcReceiverDatachannel(receiverProps)
      self.local.on("room-members", (roomMembers) => {
        console.log("on--roomMembers", roomMembers)
        self.roomMembers = roomMembers.senders
      })
      self.local.getAspxStreamsList()
      self.local.on("unknown-received-msg", self.handleUnknownReceivedMsgs)
      //self.startDataChannel()
    },
    handleUnknownReceivedMsgs(msg) {
      let self = this
      switch (msg.type) {
        case "remote-received-file":

          break
      }
    },
    startPeerSender() {
      let self = this
      self.remote.getOrCreateConnection().then((remoteConn) => {
        self.local.getOrCreateConnection().then((localConn) => {
          self.local.createPlainPeerConnection().then((peerLocal) => {
            self.remote.createPlainPeerConnection().then((peerRemote) => {
              self.remote.peer = peerRemote.pc
              self.local.peer = peerLocal.pc
              self.local.peer.oniceconnectionstatechange = function (event) {
                console.log("ICE Candidate State self.remote.peer: " + self.local.peer.iceConnectionState);
                console.log("ICE Gathering State self.remote.peer: " + self.local.peer.iceGatheringState);
              }
              self.chLocal = new DatachannelSender({ peer: self.local.peer, label: "label-local-datachannel-sender" })
              self.chLocal2 = new DatachannelReceiver({
                peer: self.local.peer,
                label: "label-local-datachannel-receiver"
              })
              self.chLocal2.on("message", self.handleDataChannelMessage2)
              self.local.peer.onicecandidate = e => {
                self.onIceCandidate(localConn, remoteConn, e, self.local)
              }
            })
          })
        })
      })
    },
    startReceivingSenderStream() {
      let self = this
      self.local.getOrCreateConnection().then((localConn) => {
        self.remote.getOrCreateConnection().then((remoteConn) => {
         // self.remote.createPlainPeerConnection().then((peerRemote) => {
           // self.remote.peer = peerRemote.pc
            console.log("self.remote.peer333333", self.remote.peer)
            self.remote.peer.oniceconnectionstatechange = function (event) {
              console.log("ICE Candidate State self.remote.peer: " + self.remote.peer.iceConnectionState);
              console.log("ICE Gathering State self.remote.peer: " + self.remote.peer.iceGatheringState);
            }
            self.remote.peer.onicecandidate = e => {
              self.onIceCandidate(remoteConn, localConn, e, self.remote)
            }
            self.chRemote = new DatachannelReceiver({
              peer: self.remote.peer,
              label: "label-remote-datachannel-receiver"
            })
            self.chRemote.on("message", self.handleDataChannelMessage)
            self.chRemote2 = new DatachannelSender({ peer: self.remote.peer, label: "label-remote-datachannel-sender" })
            self.local.makeOfferPreview(self.remotePeerStream, localConn)
         // })
        })
      })
    },
    // Methods for handle webrtc p2p connection without signal server - REMOVE LATER
    onIceCandidate(conn, connTo, event, obj) {
      let icePayload = {
        candidate: event?.candidate?.candidate,
        sdpMLineIndex: event?.candidate?.sdpMLineIndex,
        sdpMid: event?.candidate?.sdpMid,
      }
      console.log("onIceCandidate", event, icePayload)
      var msg_send = {
        from: "", // self.connection.id
        payload: icePayload,
        prefix: "webkit",
        roomType: "Video",
        sid: "",
        to: connTo.id,
        type: "candidate",
        token: obj.tokenVal,
        loginHash:
          "54BC9A3B458A4B53F605FCD9F66A645E6A7570AC1B57969E40CE2ADA44B00DBE",
      }
      conn.emit("message", JSON.stringify(msg_send))
      console.log(` ICE candidate: ${event.candidate ? event.candidate.candidate : '(null)'}`);
    },
    sendTextMessage() {
      console.log("sendMessage", this.messageSendText, this.chRemote, this.chLocal)
      console.log("connectionState--peerLocal", this.local.peer.connectionState)
      console.log("connectionState--peerRemote", this.remote.peer.connectionState)
      this.chLocal.sendData(this.messageSendText)
    },
    sendTextMessage2() {
      // console.log("sendMessage", this.messageSendText, this.chRemote, this.chLocal)
      // console.log("connectionState--peerLocal", this.local.peer.connectionState)
      // console.log("connectionState--peerRemote", this.remote.peer.connectionState)
      this.chRemote2.sendData(this.messageSendText2)
    },
    sendFiles() {
      let self = this
      let fileReader
      const chunkSize = 16384
      self.chRemoteFile = new DatachannelSender(
        { peer: self.remote.peer, label: "label-remote-dc-file-sender" },
        { binaryType: "arraybuffer" }
      )

      self.chLocalFile = new DatachannelReceiver(
        { peer: self.local.peer, label: "label-local-dc-file-sender" },
        { binaryType: "arraybuffer" }
      )
      self.chLocalFile.on("message", self.handleDataChannelFileMsg)
      self.chLocalFile.on("receiveChannelCallback", self.receiveChannelCallback)

      // self.files.forEach((file) => {
      let file = self.files
      self.sendProgress.max = file.size
      self.receiveProgress.max = file.size
      fileReader = new FileReader()
      let offset = 0
      fileReader.addEventListener('error', error => console.error('Error reading file:', error))
      fileReader.addEventListener('abort', event => console.log('File reading aborted:', event))
      fileReader.addEventListener('load', e => {
        console.log('FileRead.onload ', e)
        self.chRemoteFile.sendData(e.target.result)
        offset += e.target.result.byteLength
        self.sendProgress.value = offset
        if (offset < file.size) {
          readSlice(offset)
        }
      })
      const readSlice = o => {
        console.log('readSlice ', o)
        const slice = file.slice(offset, o + chunkSize)
        fileReader.readAsArrayBuffer(slice)
      }
      readSlice(0)
      // })
    },
    receiveChannelCallback() {
      this.chLocalFile.channel.onopen = this.onReceiveChannelStateChange
      this.chLocalFile.channel.onclose = this.onReceiveChannelStateChange
    },
    async onReceiveChannelStateChange() {
      if (this.chLocalFile.channel) {
        const readyState = this.chLocalFile.channel.readyState;
        console.log(`Receive channel state is: ${readyState}`);
        if (readyState === 'open') {
          this.timestampStart = (new Date()).getTime();
        }
      }
    },
    handleDataChannelMessage(msg) {
      console.log("handleDataChannelMessage", msg)
      this.messageReceived.push(msg)
    },
    handleDataChannelMessage2(msg) {
      console.log("handleDataChannelMessage2", msg)
      this.messageReceived2.push(msg)
    },
    handleDataChannelFileMsg(msg) {
      let self = this
      console.log("handleDataChannelFileMsg", msg, msg.size)
      this.receiveBuffer.push(msg)
      this.receivedSize += msg.size
      this.receiveProgress.value = this.receivedSize
      // we are assuming that our signaling protocol told
      // about the expected file size (and name, hash, etc).
      if (this.receivedSize === this.receiveProgress.max) {
        const received = new Blob(this.receiveBuffer)
        this.receiveBuffer = []
        // self.sendProgress.value = 0
        // self.receiveProgress.value = 0
        // self.sendProgress.max = 0
        // self.receiveProgress.max = 0

        // downloadAnchor.href = URL.createObjectURL(received);
        // downloadAnchor.download = file.name;
        // downloadAnchor.textContent =
        //   `Click to download '${file.name}' (${file.size} bytes)`;
        // downloadAnchor.style.display = 'block';


        self.bitrate = Math.round(self.receivedSize * 8 / ((new Date()).getTime() - self.timestampStart))

        // if (statsInterval) {
        //   clearInterval(statsInterval);
        //   statsInterval = null;
        // }
        //
        // closeDataChannels();
      }
    },
  },

  computed: {
    ...mapGetters({
      streamsActiveSignalServerUrl: "streamsActiveSignalServerUrl",
      streamsSignalRoom: "streamsSignalRoom",
      streamsCommonRoom: "streamsCommonRoom",
      webengineSendersSignal: "webengineSendersSignal",
    }),

    currentStreamsCompany: {
      get() {
        return this.$store.getters.streamsActiveCompany
      },
      set(val) {
        this.$store.dispatch("streamsSaveActiveCompany", val)
      },
    },

    currentSignalKey: {
      get() {
        return this.$store.getters.streamsActiveSignalKey
      },
      set(val) {
        this.$store.dispatch("streamsSaveActiveSignal", val)
      },
    },

    currentCommonRoom: {
      get() {
        return this.$store.getters.streamsCommonRoom
      },
      set(val) {
        this.$store.dispatch("streamsSaveCommonRoom", val)
      },
    },

    signalUrlHint() {
      return `Url: ${this.streamsActiveSignalServerUrl}`
    },

    signalRoomHint() {
      return `Room: ${this.streamsSignalRoom}`
    },
    sendProgressVal() {
      return this.sendProgress.value * 100 / this.sendProgress.max
    },
    receiveProgressVal() {
      return this.receiveProgress.value * 100 / this.receiveProgress.max
    },
  },
}
</script>
